import React from 'react';
import { ErrorComponentsState } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { Link } from 'react-router-dom';
import { useKeycloak } from '../keycloak-api';
import { LoginButton } from './LoginButton';

export const errorComponents: Partial<ErrorComponentsState> = {
  not_found: () => {
    const lurchi = require('../assets/lurchi_404.png');
    const { keycloak, authenticated } = useKeycloak();
    const translate = useTranslate();

    if (authenticated) {
      return (
        <div className="shrink items-center text-center">
          <p className="text-2xl">{translate('error')} 404</p>
          <p className="error">{translate('seite-existiert-nicht-text')}</p>
          <img className="self-center" src={lurchi} alt="Lurchi"></img>
          <p>
            <Link to="/" className="underline">
              {translate('zur-startseite')}
            </Link>
          </p>
        </div>
      );
    } else {
      return (
        <div className="shrink items-center text-center">
          <p className="text-2xl">{translate('error')} 404</p>
          <p className="error">{translate('seite-existiert-nicht-text')}</p>
          <p className="mt-16 flex justify-center">
            <LoginButton keycloak={keycloak} />
          </p>
        </div>
      );
    }

  },
  loading: () => {
    const lurchi = require('../assets/lurchi_404.png');
    const translate = useTranslate();

    return (
      <div className="shrink items-center text-center">
        <p className="text-2xl">{translate('error')} 504</p>
        <p className="error">{translate('feed-server-offline')}</p>
        <img className="self-center" src={lurchi} alt="Lurchi"></img>
        <p>{translate('bitte-versuche-es-spaeter-nochmal')}</p>
      </div>
    );
  },
};
