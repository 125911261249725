import * as React from 'react';
import { MenuRootItem, SipTileAndMenuApi, SipBreadcrumbItem } from './types';
import { SipTile } from './SipTile';
import { MenuType } from 'piral-menu';
import { PiralPlugin } from 'piral-core';
import { SipMenuItem } from './SipMenuItem';
import { BreadcrumbSettings } from 'piral-breadcrumbs';
import { SipBreadcrumb } from './SipBreadcrumb';
import { SipBreadcrumbTitleStorage } from './SipBreadcrumbTitleStorage';

export function createSipletApi(): PiralPlugin<SipTileAndMenuApi> {
  return context => {
    const breadcrumbTitleStorage = new SipBreadcrumbTitleStorage();
    context.dispatch(state => ({
      ...state,
      breadcrumbTitleStorage,
    }));
    return (api, target) => ({
      // Tiles im Dashboard
      registerSipTile(route: string, title: string, description: string, imgSource: string | null) {
        api.registerTile(route, () => <SipTile linkTo={route} title={title} description={description} imgSource={imgSource} />, {
          initialColumns: 2,
          initialRows: 2,
          resizable: true,
        });
      },

      unregisterSipTile(route: string) {
        api.unregisterTile(route);
      },

      // SipMenu (links)
      registerSipMenu(item: MenuRootItem, type: MenuType = 'sidebar') {
        api.registerMenu(item.route, () => <SipMenuItem item={item} />, { type });
      },

      unregisterSipMenu(item: MenuRootItem) {
        api.unregisterMenu(item.route);
      },

      registerSipBreadcrumb(item: SipBreadcrumbItem | BreadcrumbSettings) {
        if ('dynamicTitleKey' in item) {
          api.registerBreadcrumb({
            path: item.path,
            parent: item.parent,
            title: params => <SipBreadcrumb item={item} params={params} />
          });
        } else {
          api.registerBreadcrumb(item);
        }
      },

      setBreadcrumbTitle(group: string, key: string, title: string) {
        breadcrumbTitleStorage.setTitle(group, key, title);
      },
    });
  };
}
