export class SipBreadcrumbTitleStorage {
  private events: Record<string, Record<string, ((title: string) => void)[]>> = {};
  private storage: Record<string, Record<string, string>> = {};

  public on(group: string, key: string, listener: (title: string) => void) {
    if (!this.events[group]) {
      this.events[group] = {};
    }
    if (!this.events[group][key]) {
      this.events[group][key] = [];
    }
    this.events[group][key].push(listener);
  }

  public off(group: string, key: string, listenerToRemove: (title: string) => void) {
    if (!this.events[group] || !this.events[group][key]) {
      return;
    }
    this.events[group][key] = this.events[group][key].filter(listener => listener !== listenerToRemove);
  }

  public getTitle(group: string, key: string): string {
    return this.storage[group]?.[key] ?? '';
  }

  public setTitle(group: string, key: string, title: string) {
    if (!this.storage[group]) {
      this.storage[group] = {};
    }
    this.storage[group][key] = title;
    if (!this.events[group] || !this.events[group][key]) {
      return;
    }
    this.events[group][key].forEach(listener => listener(title));
  }
}
