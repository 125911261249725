import React, { useEffect, useState } from 'react';
import { SipBreadcrumbProps } from './types';
import { useGlobalState } from 'piral-core';

export const SipBreadcrumb: React.FC<SipBreadcrumbProps> = ({ item, params }) => {
  const { breadcrumbTitleStorage } = useGlobalState();

  const [ title, setTitle ] = useState('');

  useEffect(() => {
    const key = item.title(params);
    const storedTitle = breadcrumbTitleStorage.getTitle(item.dynamicTitleKey, key);
    if (storedTitle) {
      setTitle(storedTitle);
    } else {
      setTitle(key);
    }
    breadcrumbTitleStorage.on(item.dynamicTitleKey, key, setTitle);
    return () => breadcrumbTitleStorage.off(item.dynamicTitleKey, key, setTitle);
  }, [item, params]);


  return <>{title}</>;
};
