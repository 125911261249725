import { PiralPlugin } from 'piral-core';
import { PiralKeycloakApi } from './types';
import { keycloakClient, tokenRefreshedEventEmitter } from './client';

/**
 * Creates new Pilet API extensions for enabling keycloak support.
 */
export function createKeycloakApi(): PiralPlugin<PiralKeycloakApi> {
  return context => {
    context.dispatch(state => ({
      ...state,
      keycloak: keycloakClient,
      keycloakTokenRefreshedEventEmitter: tokenRefreshedEventEmitter,
    }));

    return {
      getKeycloakClient() {
        return context.readState(s => s.keycloak);
      },
      addOnKeycloakTokenRefreshedListener(eventHandler: () => void) {
        context.readState(s => s.keycloakTokenRefreshedEventEmitter.addEventListener('refreshed', eventHandler));
      },
    };
  };
}
